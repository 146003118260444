import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { REMOVE_LOCATIONS_FROM_LIST } from '../../../graphql/queries/my-lists'
import { CButton, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro'
import ListSelect from './ListSelect'

const MoveLocation = ({ listId, locationId, onComplete }) => {
  const [visible, setVisible] = useState(false)

  const [remove] = useMutation(REMOVE_LOCATIONS_FROM_LIST, {
    variables: {
      locations: [locationId],
      id: listId,
    },
    onCompleted() {
      onComplete()
      setVisible(false)
    },
  })

  return (
    <>
      <CModal visible={visible} onClose={() => setVisible(false)} unmountOnClose>
        <CModalHeader>
          <CModalTitle>Move Location</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Select a list to which to move this location, or create a new list.</p>
          <ListSelect
            hideLimitInformation
            onlyAdd
            locations={[locationId]}
            onComplete={() => {
              remove()
            }}
            onCancel={() => setVisible(false)}
          />
        </CModalBody>
      </CModal>
      <CButton color={'primary'} onClick={() => setVisible(true)}>
        Move
      </CButton>
    </>
  )
}

export default MoveLocation
