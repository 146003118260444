import React, {useContext, useEffect, useRef, useState} from 'react'
import {
  CImage,
  CNavTitle,
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CSidebarNav,
} from '@coreui/react-pro'
import ReactImg from '../../../assets/brand/logo-white.png'

import items from './nav'
import { Link } from 'react-router-dom'
import ContainedNavLink from './Components/ContainedNavLink'
import { AuthContext } from '../../../hooks/auth-context'
import { useReactiveVar } from '@apollo/client'
import { showSidebar, collapseSidebar } from '../../../graphql/policies/nav'
import { CAvatar } from '@coreui/react'
import NavClose from "./Components/NavClose";

const Sidebar = () => {

  const sidebarRef = useRef()

  const isSidebarCollapsed = useReactiveVar(collapseSidebar)
  const isSidebarVisible = useReactiveVar(showSidebar)
  const { user: { name } = { name: '' }, anyAllow } = useContext(AuthContext)

  const outsideContext = (e) => {
    if(e.target !== sidebarRef.current && !sidebarRef.current?.contains(e.target)) {
      showSidebar(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', outsideContext)
    return () => void document.body.removeEventListener('click', outsideContext)
  }, [isSidebarCollapsed]);


  return (
    <>
      <div ref={sidebarRef} className={`monitor-sidebar${isSidebarVisible ? ' active':''}${ isSidebarCollapsed? ' collapsed':''}`}>
        <div className={"monitor-sidebar__header d-flex justify-content-between"}>
          <span className={"monitor-sidebar__header--title"}>
            <CImage src={ReactImg} height={35} />
          </span>
          <NavClose />
        </div>
        <ul role={"navigation"} className={"monitor-sidebar__nav"}>
          {items &&
            items.map((item, id) => {
              let allRoles = []
              if (item.roles) {
                allRoles = [...item.roles]
              }
              if (item.children) {
                item.children.forEach((child) => {
                  if (child.roles) {
                    allRoles = Array.from(new Set([...allRoles, ...child.roles]))
                  }
                })
              }

              if (!item.children || item.children.length === 0) {
                if (anyAllow(allRoles)) {
                  return <ContainedNavLink item={item} />
                }
              }

              if (item.children && item.children.length > 0) {
                const children = item.children.filter(
                  (child) => !child.roles || anyAllow(child.roles),
                )

                if (children.length === 0) {
                  return <></>
                }

                return (
                  <React.Fragment key={`group-${id}-${item.name}`}>
                    <li className={"monitor-sidebar__nav-title"}>{item.name}</li>

                    <>
                      {children.map((child) => (
                        <ContainedNavLink item={child} />
                      ))}
                    </>
                  </React.Fragment>
                )
              }

              return <React.Fragment key={`empty-item-${id}`}></React.Fragment>
            })}
        </ul>
        {/*<div className={"monitor-sidebar__footer"}>*/}
        {/*  <Link to={'/myaccount'}>*/}
        {/*    <CAvatar color={'white'} size={'lg'} textColor={'black-50'}>*/}
        {/*      {name && name.charAt(0)}*/}
        {/*    </CAvatar>*/}
        {/*  </Link>*/}
        {/*</div>*/}
      </div>
    </>

  )
}

export default Sidebar
