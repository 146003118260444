import {gql} from "@apollo/client";

export const CLINIC_FRAGMENT = gql`
  fragment ClinicFragment on CompIntelligenceClinic {
    external_id
    name
    brands
    address {
        line1 line2
        locality postal_code
        region
    }
    manufacturers
    phone_number
    treatment_categories
    market_classification
    website
    company_size
    is_new
    location {
      lat lon
    }
  }
`

export const MINIMAL_CLINIC = gql`
  query MinimalClinic($external_id: String!) {
    clinic(externalId: $external_id) {
      name address {
        line1
        line2
        locality
        region
        postal_code
      }
      lists {
        id name
      }
      is_new
    }
  }
`

export const FETCH_CLINIC = gql`
  query SingleClinic($externalId: String! $anPage: Int! = 1) {
    clinic(externalId: $externalId) {
      external_id
      lists {
        id name colour
      }
      name
      address {
        line1
        line2
        locality
        region
        postal_code
      }
      phone_number
      market_classification
      brands
      manufacturers
      treatment_categories
      website
      contacts {
        name
        activity
      }
      company_size
      company_financials
      legal_name
      companies_house_number
      providerId
      average_income
      households
      population
      created_at
      is_new
      location {
        lat lon
      }
      social_data
      products
      hcp_contacts
      annotations(params: {page: $anPage limit: 10} ) {
        entries {
          id content createdAt user {
            id name
          }
        }
        totalCount
      }
    }
  }
`

export const ADD_NOTE = gql`
  mutation AddNote($body: String! $locationId: ID!) {
    AnnotateLocation(body: $body locationId: $locationId )
  }
`

export const REMOVE_NOTE = gql`
  mutation RemoveNote($id: ID!) {
    RemoveLocationAnnotation(id: $id)
  }
`
