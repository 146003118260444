import React from "react";
import {NavLink} from "react-router-dom";
import {CNavItem} from "@coreui/react-pro";

const ContainedNavLink = ({item}) => {
  return <li className={"monitor-sidebar__nav-item"} title={item.name}>

    <NavLink className={"monitor-sidebar__nav-link"} to={item.to} title={item.name}>
      {item.icon}
      {item.name}
    </NavLink>
  </li>
}

export default ContainedNavLink
