import React from 'react'
import Page404 from './views/pages/page404/Page404'
import CheckAuth from './components/Auth/check-auth'
import Login from './views/pages/login/Login'
import WithAuth from './components/Auth/with-auth'
import DefaultLayout from './layout/DefaultLayout'
import Treatments from './components/Glossary/Components/Treatments'
import Products from './components/Glossary/Components/Products'
import { Outlet } from 'react-router-dom'
import Searches from './components/Manage/Searches'
import Users from './components/Manage/Users'
import UserEdit from './components/Manage/Users/Edit'
import UserCreate from './components/Manage/Users/Create'
import EditSearch from './components/Manage/Searches/EditSearch'
import CreateSearch from './components/Manage/Searches/CreateSearch'
import MyLists from './components/MyLists'
import ViewList from './components/MyLists/ViewList'
import EditList from './components/MyLists/EditList'
import CreateList from './components/MyLists/CreateList'
import Territories from './components/Manage/Territories'
import EditTerritory from './components/Manage/Territories/EditTerritory'
import CreateTerritory from './components/Manage/Territories/CreateTerritory'
import { SearchProvider } from './hooks/search-context'
import {GeoJSONDataProvider} from "./contexts/geojson-data";
import {AggSearchProvider} from "./contexts/agg-search";

const Dashboard = React.lazy(() => import('./components/Dashboard'))
const MyAccount = React.lazy(() => import('./views/dashboard/myAccount'))
const Glossary = React.lazy(() => import('./components/Glossary'))
const LocationDetail = React.lazy(() => import ('./components/ClinicDetail'))

const clinic = {
  path: 'clinic',
  name: 'Clinic',
  children: [
    {
      path: ':locationId',
      name: 'View Location',
      element: <LocationDetail/>,
      exact: true,
      handle: {
        crumb: (data) => {
          return <>View Clinic </>
        },
      },
    },
  ],
}

const routes = [
  {
    name: 'Home',
    element: (
      <WithAuth>
        <GeoJSONDataProvider>
          <AggSearchProvider>
        <DefaultLayout/>
        {/*<DataPreload />*/}
          </AggSearchProvider>
        </GeoJSONDataProvider>
      </WithAuth>
    ),
    children: [
      {
        path: '/',
        name: 'Dashboard',

        handle: {
          crumb: (data) => {
            return 'Dashboard'
          }
        },
        children: [
          {
            path: "/",
            element: <SearchProvider><Dashboard /></SearchProvider>,
          },
          {
            children: [
              clinic,
            ],
          }
        ],
      },
      {
        path: 'myaccount',
        name: 'My Account',
        element: <MyAccount/>,
        exact: true,
        handle: {crumb: () => 'My Account'},
      },
      {
        path: '/glossary',
        name: 'Glossary',
        handle: {
          crumb: () => 'Glossary',
        },
        children: [
          {index: true, name: 'Glossary Index', element: <Glossary/>},
          {
            path: 'products',
            name: 'Products',
            element: <Products/>,
            exact: true,
            handle: {
              crumb: (data) => {
                return 'Product Catalogue'
              },
            },
          },
          {
            path: 'treatments',
            name: 'Treaments',
            element: <Treatments/>,
            exact: true,
            handle: {
              crumb: (data) => {
                return 'Treatment Catalogue'
              },
            },
          },
        ],
      },
      {
        path: "my-lists",
        name: "My Lists",
        handle: {
          crumb: (data) => "My Lists"
        },
        children: [
          {index: true, element: <MyLists/>},
          clinic,
          {
            path: "view/:listId",
            name: "View List",
            element: <ViewList/>,
            handle: {
              crumb: (data) => "View List"
            },
          },
          {
            path: "edit/:listId",
            name: "Edit List",
            element: <EditList/>,
            handle: {
              crumb: (data) => "Edit List"
            }
          },
          {
            path: "create",
            name: "Create List",
            element: <CreateList/>,
            handle: {
              crumb: (data) => "Create List",
            }
          }
        ]
      },
      {
        path: "manage",
        name: "Management",
        element: <React.Fragment><Outlet/></React.Fragment>,
        exact: true,
        handle: {
          crumb: (data) => {
            return "Management"
          }
        },
        children: [
          {
            index: true,
            element: <><h3>Management Page Information</h3></>
          },
          {
            path: "users",
            exact: true,
            name: "Manage Users",
            handle: {
              crumb: (data) => {
                return "Users"
              }
            },
            children: [
              {
                index: true,
                element: <Users/>,
              },
              {
                name: "Create User",
                path: "create",
                element: <UserCreate/>,
                handle: {
                  crumb: (data) => {
                    return "Create Sub-User"
                  }
                }
              },
              {
                name: "Edit User",
                path: "edit/:userId",
                element: <UserEdit/>,
                handle: {
                  crumb: (data) => {
                    return "Edit Sub-User"
                  }
                }
              },
            ],
          },
          {
            path: "searches",
            exact: true,
            name: "Manage Searches",
            handle: {
              crumb: (data) => {
                return "Searches"
              }
            },
            children: [
              {
                index: true,
                element: <Searches/>,
              },
              {
                name: "Create Search",
                path: "create",
                element: <CreateSearch/>,
                handle: {
                  crumb: (data) => {
                    return "Create Search"
                  }
                },
              },
              {
                name: "Edit Search",
                path: "edit/:searchId",
                element: <EditSearch/>,
                handle: {
                  crumb: (data) => {
                    return "Edit Search"
                  }
                },
              },
            ],
          },
          {
            path: "territories",
            exact: true,
            handle: {
              crumb: () => "Territories"
            },
            children: [
              {
                index: true,
                element: <Territories/>
              },
              {
                path: "edit/:territoryId",
                handle: {
                  crumb: () => "Edit Territory"
                },
                element: <EditTerritory/>
              },
              {
                path: "create",
                handle: {
                  crumb: () => "Create New Territory"
                },
                element: <CreateTerritory/>
              }
            ]
          }
        ]
      }
    ],
  },
  {
    path: 'login',
    name: 'Login Page',
    element: (
      <CheckAuth>
        <Login />
      </CheckAuth>
    ),
    exact: true,
  },
  { path: '*', name: 'Page 404', element: <Page404 /> },
]


export default routes
