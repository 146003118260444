import React, {useContext, useEffect, useState} from 'react'
import { useQuery } from '@apollo/client'
import { LIST_LOCATION_LISTS } from '../../graphql/queries/my-lists'
import { CSmartTable } from '@coreui/react-pro'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCallout,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCollapse,
  CPopover,
} from '@coreui/react'
import DeleteList from './Components/DeleteList'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons-pro'
import LimitInformation from './Components/AddLocationsToList/LimitInformation'
import ColourChip from '../Utility/Components/ColourChip'
import JSONPretty from "react-json-pretty";
import UserSelect from "../Utility/UserSelect";
import {AuthContext} from "../../hooks/auth-context";

const MyLists = () => {
  const [infoVisible, setInfoVisible] = useState(false)

  const [filter, setFilter] = useState([])
  const [selectedUser, setSelectedUser] = useState("")
  const {user: {id: userId} = {id: ""}} = useContext(AuthContext)


  const limit = 20
  const columns = [
    {
      key: 'name',
      label: 'Name',
      filter: false,
    },
    {
      key: 'colour',
      name: 'Colour',
      _style: {
        width: '2rem',
        textAlign: 'center',
      },
      filter: false,
    },
    {
      key: 'creator',
      _style: {
        width: '10%',
        minWidth: '20rem',
      },
      filter: (values, onChange) => {
        return <UserSelect selected={selectedUser} onChange={(id) => {
          if(!id || id === "-1") {
            setFilter([])
          } else {
            setFilter([{field: "user_id", value: id}])
          }
          setSelectedUser(id)
        }} />
      },
    },
    {
      key: 'length',
      label: 'Location Count',
      _style: {
        minWidth: '5rem',
        width: '5%',
        textAlign: 'center',
      },
      filter: false,
    },
    {
      key: 'options',
      label: 'Options',
      _style: {
        maxWidth: '25%',
        width: '25%',
        minWidth: '15rem',
      },
      filter: false,
    },
  ]
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)



  const {
    loading,
    data: { ListUserLocationLists: { totalCount, entries: items } } = {
      ListUserLocationLists: { totalCount: 0, entries: [] },
    },
    refetch,
  } = useQuery(LIST_LOCATION_LISTS, {
    variables: {
      page,
      limit,
      filter,
    },
    onCompleted({ ListUserLocationLists: { totalCount, pageInfo, entries } }) {
      // setTotalCount(totalCount)
      // setItems(entries)
    },
  })

  useEffect(() => {
    let p = 1
    if (totalCount > 0) {
      p = Math.ceil(totalCount / limit)
      if (p < 1) {
        p = 1
      }
    }
    setPages(p)
  }, [totalCount, limit])

  return (
    <>
      <CCard>
        <CCardHeader>
          <CCardTitle>
            My Lists{' '}
            <CPopover content={'Click for more information'} trigger={'hover'}>
              <span style={{ cursor: 'pointer' }} onClick={() => setInfoVisible(!infoVisible)}>
                <CIcon icon={cilInfo}></CIcon>{' '}
              </span>
            </CPopover>
          </CCardTitle>
        </CCardHeader>
        <CCardBody>
          <CCollapse visible={infoVisible}>
            <CCallout color={'info'}>
              <LimitInformation />
              <CButton color={'primary'} size={'sm'} onClick={() => setInfoVisible(false)}>
                OK
              </CButton>
            </CCallout>
          </CCollapse>

          <Link to={'create'} className={'btn btn-primary'}>
            Create New List
          </Link>

          <CSmartTable
            loading={loading}
            className={'mt-3'}
            itemsPerPage={limit}
            columns={columns}
            columnFilter={{ external: true }}
            pagination={pages > 1}
            paginationProps={{
              onActivePageChange: setPage,
              activePage: page,
              pages,
            }}
            items={items}
            scopedColumns={{
              colour: (item) => (
                <td>
                  <ColourChip colour={item.colour} center />
                </td>
              ),
              creator: (item) => <td>{item.user.name}</td>,
              name: (item) => (
                <td>
                  <Link to={`view/${item.id}`}>{item.name}</Link>
                </td>
              ),
              length: (item) => (
                <td style={{ textAlign: 'center' }}>{item.locations.totalCount ?? '0'}</td>
              ),
              options: (item) => (
                <td align={'right'}>

                  {item.user.id === userId && <DeleteList id={item.id} onDelete={() => refetch()} />}
                </td>
              ),
            }}
          />
        </CCardBody>
      </CCard>
    </>
  )
}

export default MyLists
