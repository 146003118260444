import React, { useEffect, useState } from 'react'
import Lists from '../AddLocationsToList/Lists'
import NewList from '../AddLocationsToList/NewList'
import LimitInformation from '../AddLocationsToList/LimitInformation'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons-pro'
import {CTab, CTabList, CTabs, CTabPane, CTabPanel, CTabContent, CButton} from "@coreui/react-pro";

const ListSelect = ({
  locations = [],
  onComplete = async () => void 0,
  onError = (e) => void 0,
  onlyAdd,
  showModal = false,
  hideLimitInformation = false,
  exclude = [],
  onCancel,
}) => {
  const [activeTab, setActiveTab] = useState("current")

  useEffect(() => {
    return () => setActiveTab('current')
  }, [])

  return (
    <>
      <CTabs
        activeItemKey={activeTab}
        onChange={(tab) => {
          console.log("TAB CHANGE", tab)
          setActiveTab(tab)
        }}
      >
        <CTabList variant={"tabs"}>
          <CTab itemKey={'current'}>
            Current Lists
          </CTab>
          <CTab itemKey={'new'}>
            New List
          </CTab>
          {!hideLimitInformation && <CTab itemKey={'info'}>
            <CIcon icon={cilInfo} size={'sm'} /> <strong>Lists Information</strong>
          </CTab>}
        </CTabList>
        <CTabContent>
          <CTabPanel itemKey={'current'}>
            <Lists
              locations={locations}
              onComplete={onComplete}
              onError={onError}
              onlyAdd={onlyAdd}
              showModal={showModal}
              exclude={exclude}
              onCancel={onCancel}
            />
          </CTabPanel>

          <CTabPanel itemKey={'new'}>
            <NewList locations={locations} onComplete={onComplete} onCancel={onCancel} showModal={showModal} />{' '}
          </CTabPanel>
          {!hideLimitInformation && <CTabPanel
            itemKey={'info'}
            className={'mt-3'}

          >
            <LimitInformation />
          </CTabPanel>}
        </CTabContent>
      </CTabs>
      {activeTab !== 'new' && <div className={"text-end"}>
        <CButton color={"primary"} onClick={() => onCancel()}>Close</CButton>
      </div>}
    </>
  )
}

export default ListSelect
