import React, {useContext, useEffect, useState} from 'react'
import { SideSegment } from '../index'
import ColourChip from '../../Utility/Components/ColourChip'
import {CFormSwitch} from "@coreui/react";
import {AuthContext} from "../../../hooks/auth-context";
import JSONPretty from "react-json-pretty";

const UserList = ({ segment: inSegment, onChange, children, ...props }) => {
  const [segment, setSegment] = useState({})
  const [customOpts, setCustomOpts] = useState([])
  const [showAllLists, setShowAllLists] = useState(false)
  const {user: {id: userId}} = useContext(AuthContext)
  useEffect(() => {
    setSegment({ ...inSegment, strings: [] })
    if (inSegment.strings) {
      setCustomOpts(
        [...inSegment.strings]
          .filter((el) => {
            if(showAllLists) {
              return true
            }

            return el.userId === userId
          })
          .sort((a, b) => {
            if (a.name > b.name) {
              return 1
            } else if (a.name < b.name) {
              return -1
            }
            return 0
          })
          .map((el) => ({
            ...el,
            renderName: (
              <div className={'d-flex align-content-center'}>
                <ColourChip colour={el.colour} />{' '}
                <span style={{ margin: 'auto 0' }}>{el.name}</span>
              </div>
            ),
          })),
      )
    }
  }, [inSegment, showAllLists])

  return (
    <>
      <SideSegment
        segment={segment}
        {...props}
        customOpts={customOpts}
        ops={['or', 'not']}
        onChange={onChange}
        actions={[
          <CFormSwitch label={"Show All Lists"} onChange={() => setShowAllLists(!showAllLists)} checked={showAllLists}/>,
        ]}
      ></SideSegment>
    </>
  )
}

export default UserList
