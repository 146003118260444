import React from 'react'
import { CSmartTable } from '@coreui/react-pro'
import {CButton, CFormSelect, CListGroup, CListGroupItem, CTooltip} from '@coreui/react'
import { Link } from 'react-router-dom'
import CIcon from "@coreui/icons-react";
import {cilInfo, cilTrash} from "@coreui/icons-pro";
import RemoveLocationFromList from "../RemoveLocationFromList";
import SocialMedia from "../SocialMedia";
import JSONPretty from "react-json-pretty";
import BetaBadge from "../../../Utility/BetaBadge";
import AddLocationsToList from "../AddLocationsToList";
import MoveLocation from "../MoveLocation";

const LocationList = ({ locations, listId, pageSize, setPage = (page) => void 0, page = 1, pages = 1, onChange = () => void 0 }) => {
  const columns = [
    { key: 'name', _style: {width: "20%", maxWidth: "5rem"} },
    { key: 'phone_number', label: 'Phone Number' },
    { key: 'address', label: 'Address', _style: {width: "16rem"} },
    { key: 'social', label: <CTooltip content={"Active state of social media is not guaranteed"}><span>Social Media <CIcon size={"sm"} icon={cilInfo} /></span></CTooltip> },
    { key: 'contacts' },
    // { key: 'hcp_contacts', label: <><BetaBadge style={{marginRight: "0.5rem"}} /><CTooltip content={"Professional contact information discovered from this location's internet presence."}><span>Website Contacts <CIcon size={"sm"} icon={cilInfo} /></span></CTooltip> </> },
    { key: 'options' },
  ]
  return (
    <>
      <CSmartTable
        columns={columns}
        itemsPerPage={pageSize}
        items={locations}
        pagination={pages > 1}
        scopedColumns={{
          name: (item) => (
            <td>
              {item.visible && <Link to={`/my-lists/clinic/${item.external_id}`}>{item.name}</Link>}
              {!item.visible && <><p>{item.name}</p><em>This location is not available with your current subscription</em></>}
            </td>
          ),
          address: (item) => (
            <td>
              {item.visible && <address>
                <>
                  {Object.keys(item.address)
                    .filter((k) => item.address[k].length > 0 && k !== '__typename')
                    .map((k) => (
                      <div>{item.address[k]}</div>
                    ))}
                </>
              </address>}
            </td>
          ),
          social: (item) => {
            return (<td>
              {item.visible && <SocialMedia socialMedia={item.social_data ?? []} /> }
            </td>)
          },
          contacts: (item) => {
            const contacts = item.contacts.filter((contact) => contact.name.length > 0)
            return (
              <td>
                {item.visible && contacts.length > 0 &&
                <CListGroup flush>
                  <>{contacts.map((contact) => <CListGroupItem>
                    {contact.name}
                  </CListGroupItem>)}</>
                </CListGroup>}
              </td>
            )
          },
          hcp_contacts: (item) => {
            const contacts = item.hcp_contacts.filter((contact) => contact.name.length > 0)
            return (
              <td>
                {item.visible && contacts.length > 0 &&
                  <CListGroup flush>
                    <>{contacts.map((contact) => <CListGroupItem>
                      {contact.name}
                    </CListGroupItem>)}</>
                  </CListGroup>}
              </td>
            )
          },
          options: (item) => (<td className={"d-flex justify-content-end gap-1"}>
            <MoveLocation locationId={item.external_id} listId={listId} onComplete={onChange} />
            <RemoveLocationFromList id={listId} location_id={item.external_id} onComplete={onChange} />
          </td> )
        }}
        paginationProps={{
          onActivePageChange: (page) => setPage(page),
          activePage: page,
          pages,
        }}
      />
    </>
  )
}

export default LocationList
